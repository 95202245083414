var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.infoColab,"loading":_vm.loading,"paginate":true,"search":_vm.search,"actualPage":1,"lastPage":_vm.nLastPage,"sort-by":"colaborador"},on:{"pageChange":function($event){return _vm.fetchColaboradoresEmpresas(_vm.filters, $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista Colaboradores por Empresa")]),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(95),expression:"95"}],on:{"click":function($event){(_vm.dialog = true), (_vm.labelBtn = 'Salvar')}}}),_c('Filtro',{on:{"selectedFilters":function($event){_vm.fetchColaboradoresEmpresas((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('BaseSegmento',{attrs:{"segmento":item.id_band}})]}},{key:"item.colaborador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.colaborador))+" ")]}},{key:"item.funcao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cod_funcao)+" - "+_vm._s(_vm._f("TitleCase")(item.funcao))+" ")]}},{key:"item.departamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.departamento))+" ")]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.formatStatus(item.ativo) == 'Ativo' ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(_vm.formatStatus(item.ativo))+" ")])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(96),expression:"96"}],attrs:{"name":'edit'},on:{"click":function($event){return _vm.editItem(item)}}}),_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(1342),expression:"1342"}],attrs:{"name":'bond'},on:{"click":function($event){return _vm.vinculaMultEmpresa(item)}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"can-access",rawName:"v-can-access",value:(1402),expression:"1402"}],staticClass:"ml-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.vinculaDeptoEmpresa(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v("Vincular depto")])]),_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(424),expression:"424"}],attrs:{"name":'delete'},on:{"click":function($event){return _vm.deleteItem(item)}}})]}}],null,true)}),_c('ModalColaboradorEmpresa',{attrs:{"dialog":_vm.dialog,"labelBtn":_vm.labelBtn,"editColaboradorEmpresa":_vm.colaborador},on:{"close":function($event){return _vm.refresh()}}}),_c('DraggableColabMultEmp',{attrs:{"dialog":_vm.dialogMultEmp,"dadosColab":_vm.colabMultEmp},on:{"close":function($event){_vm.dialogMultEmp = false}}}),_c('DraggableColabDeptoEmp',{attrs:{"dialog":_vm.dialogDeptoEmp,"dadosColab":_vm.colabDeptoEmp},on:{"close":function($event){_vm.dialogDeptoEmp = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
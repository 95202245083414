<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{ labelBtn != "Salvar" ? "Editar Meta" : "Cadastrar Meta" }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row align="center">
            <v-col xl="6" lg="4" md="6" sm="12" cols="12">
              <BaseSelect
                label="Empresa"
                v-model="form.id_empresa"
                service="sistemaService.empresa"
                item-text="apelido"
                item-value="id_empresa"
                prepend-inner-icon="mdi-domain"
                :rules="[rules.required]"
                clearable
                :isCompany="true"
                :multiple="false"
              />
            </v-col>
            <v-col xl="6" lg="4" md="6" sm="12" cols="12">
              <BaseSelect
                label="Colaborador"
                v-model="form.id_colaborador"
                service="sistemaService.colaboradorEmpresa"
                prepend-inner-icon="mdi-account"
                item-text="colaborador"
                item-value="id_colaborador"
                :rules="[rules.required]"
                clearable
                :filters="{ id_empresa: form.id_empresa, ativo: 'S' }"
                :multiple="false"
              />
            </v-col>
            <v-col xl="6" lg="4" md="6" sm="12" cols="12">
              <BaseSelect
                label="Função"
                v-model="form.id_funcao"
                service="sistemaService.funcoes"
                item-text="descricao"
                item-value="id_funcao"
                prepend-inner-icon="mdi-account-tie"
                :rules="[rules.required]"
                clearable
                :hasCode="true"
                :multiple="false"
              />
            </v-col>
            <v-col xl="6" lg="4" md="6" sm="12" cols="12">
              <BaseSelect
                label="Departamento"
                v-model="form.id_depto"
                service="comissaoService.departamentos"
                item-text="departamento"
                item-value="id_depto"
                prepend-inner-icon="mdi-toolbox"
                :rules="[rules.required]"
                clearable
                :multiple="false"
              />
            </v-col>
            <v-col xl="2" lg="4" md="6" sm="12" cols="12">
              <v-switch
                v-model="form.ativo"
                label="Ativo"
                true-value="S"
                false-value="N"
              ></v-switch>
            </v-col>
            <v-col xl="3" lg="4" md="6" sm="12" cols="12">
              <v-switch
                v-model="form.calcula_comissao"
                label="Calcula Comissão"
                true-value="S"
                false-value="N"
              ></v-switch>
            </v-col>
            <v-col xl="3" lg="4" md="6" sm="12" cols="12">
              <v-switch
                v-model="form.export_totvs"
                label="Exporta Totvs"
                true-value="S"
                false-value="N"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? put() : send()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import BaseSelect from "@/components/shared/BaseSelectService";
import sistema from "@/services/http/sistemaService";

export default {
  name: "ModalColaboradoresEmpresa",

  mixins: [rules],

  components: {
    BaseSelect
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    },
    editColaboradorEmpresa: {
      type: Object
    }
  },

  data() {
    return {
      form: {}
    };
  },
  watch: {
    editColaboradorEmpresa(value) {
      this.form = value;
    }
  },

  methods: {
    async send() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await sistema()
          .colaboradorEmpresa()
          .store(this.form, {
            notification: true,
            message: "Colaborador cadastrado com sucesso!"
          });
        await sistema()
          .colaboradoresDepartamentos()
          .store(
            {
              id_colaborador: this.form.id_colaborador,
              id_depto: this.form.id_depto
            },
            {
              notification: true,
              message: "Colaborador cadastrado com sucesso!"
            }
          );
      }
    },

    async put() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await sistema()
          .colaboradorEmpresa(this.form.id_colaborador_empresa)
          .update(this.form, {
            notification: true,
            message: "Colaborador editado com sucesso!"
          });
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>

<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoColab"
      :loading="loading"
      :paginate="true"
      :search="search"
      :actualPage="1"
      :lastPage="nLastPage"
      sort-by="colaborador"
      @pageChange="fetchColaboradoresEmpresas(filters, $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista Colaboradores por Empresa</v-toolbar-title>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="95"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <Filtro
            @selectedFilters="fetchColaboradoresEmpresas((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>
      <template v-slot:[`item.colaborador`]="{ item }">
        {{ item.colaborador | TitleCase }}
      </template>
      <template v-slot:[`item.funcao`]="{ item }">
        {{ item.cod_funcao }} - {{ item.funcao | TitleCase }}
      </template>
      <template v-slot:[`item.departamento`]="{ item }">
        {{ item.departamento | TitleCase }}
      </template>

      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip
          :color="formatStatus(item.ativo) == 'Ativo' ? 'green' : 'red'"
          dark
        >
          {{ formatStatus(item.ativo) }}
        </v-chip>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          v-can-access="96"
          icon
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>-->
        <IconBottom v-can-access="96" @click="editItem(item)" :name="'edit'" />
        <IconBottom
          :name="'bond'"
          v-can-access="1342"
          @click="vinculaMultEmpresa(item)"
        />

        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can-access="1342"
              icon
              @click="vinculaMultEmpresa(item)"
              class="ml-2"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-swap-horizontal</v-icon>
            </v-btn>
          </template>
          <span>Vincular empresas</span>
        </v-tooltip>-->

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can-access="1402"
              icon
              @click="vinculaDeptoEmpresa(item)"
              class="ml-2"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account-arrow-right</v-icon>
            </v-btn>
          </template>
          <span>Vincular depto</span>
        </v-tooltip>

        <!--<v-btn
          v-can-access="424"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->
        <IconBottom
          :name="'delete'"
          @click="deleteItem(item)"
          v-can-access="424"
        />
      </template>
    </BaseTable>
    <ModalColaboradorEmpresa
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editColaboradorEmpresa="colaborador"
      @close="refresh()"
    />
    <DraggableColabMultEmp
      :dialog="dialogMultEmp"
      :dadosColab="colabMultEmp"
      @close="dialogMultEmp = false"
    />
    <DraggableColabDeptoEmp
      :dialog="dialogDeptoEmp"
      :dadosColab="colabDeptoEmp"
      @close="dialogDeptoEmp = false"
    />
  </div>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable";
import BaseSegmento from "@/components/shared/BaseSegmento";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import Filtro from "./Filtro";
import ModalColaboradorEmpresa from "./ModalColaboradorEmpresa";
import DraggableColabMultEmp from "./DraggableColabMultEmp";
import DraggableColabDeptoEmp from "./DraggableColabDepto.vue";
import sistema from "@/services/http/sistemaService";

export default {
  name: "ListaColabEmpresa",

  components: {
    BaseTable,
    BaseSegmento,
    RegisterBottom,
    Filtro,
    ModalColaboradorEmpresa,
    DraggableColabMultEmp,
    DraggableColabDeptoEmp,
    IconBottom
  },

  data() {
    return {
      search: "",
      loading: false,
      infoColab: [],
      nLastPage: 0,
      colaborador: {},
      colabMultEmp: {},
      colabDeptoEmp: {},
      labelBtn: "",
      dialog: false,
      dialogMultEmp: false,
      dialogDeptoEmp: false,
      headers: [
        { text: "Bandeira", value: "id_band" },
        { text: "Apelido", value: "apelido" },
        { text: "CPF", value: "cpf" },
        { text: "Colaborador", value: "colaborador" },
        { text: "Função", value: "funcao" },
        { text: "Departamento", value: "departamento" },
        { text: "Situacao", value: "situacao" },
        { text: "Status", value: "ativo" },
        { text: "Ações", value: "acoes" }
      ],
      filters: {}
    };
  },

  methods: {
    formatStatus(item) {
      if (item == "S") item = "Ativo";
      if (item == "N") item = "Inativo";
      return item;
    },
    async fetchColaboradoresEmpresas(filters, nPage = 1) {
      this.loading = true;
      let { data } = await sistema()
        .colaboradorEmpresa()
        .show({ page: nPage, ativo: "S", ...filters });
      this.nLastPage = data.last_page;
      this.loading = false;
      this.infoColab = data.data;
    },
    refresh() {
      this.dialog = false;
      this.fetchColaboradoresEmpresas(this.filters);
    },

    vinculaMultEmpresa(item) {
      this.colabMultEmp = Object.assign({}, item);
      this.dialogMultEmp = true;
    },

    vinculaDeptoEmpresa(item) {
      this.colabDeptoEmp = Object.assign({}, item);
      this.dialogDeptoEmp = true;
    },

    editItem(item) {
      this.colaborador = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          `Deseja deletar colaborador ${item.colaborador}?`,
          "Essa ação não pode ser desfeita"
        );
        sistema()
          .colaboradorEmpresa(item.id_colaborador_empresa)
          .delete(
            {},
            {
              notification: true,
              message: "Colaborador deletado com sucesso!"
            }
          );
      } catch (error) {
        console.log(error);
      } finally {
        this.fetchColaboradoresEmpresas(this.filters);
      }
    }
  },

  mounted() {
    this.fetchColaboradoresEmpresas();
  }
};
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="70%" scrollable>
    <v-card>
      <v-card-title
        class="flex-column align-start
"
      >
        <span class="headline">
          Colaborador Mult-Empresa: {{ dadosColab.colaborador }}
        </span>
        <BaseSelect
          label="Indicador"
          v-model="form.id_indicador"
          service="sistemaService.indicadores"
          item-text="indicador"
          item-value="id_indicador"
          prepend-inner-icon="mdi-finance"
          clearable
          :filters="{ ativo: 'S' }"
          :hasCode="true"
          :multiple="false"
          @change="vinculo()"
        />
        <v-alert
          dense
          text
          :icon="false"
          type="warning"
          v-if="!form.id_indicador"
        >
          <v-icon class="mr-2" color="warning">mdi-alert-circle</v-icon>
          Selecione um indicador para visualizar o conteúdo
        </v-alert>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="red">mdi-close</v-icon>Empresas não
              Vinculadas
            </h4>
            <v-btn
              class="mb-3"
              color="success"
              @click="addTodos()"
              :disabled="naoVinculados.length == 0"
            >
              Adicionar todas
            </v-btn>
            <draggable
              handle=".handle"
              class="draggable-area exclude-companies"
              :list="naoVinculados"
              group="equipes"
              @change="moveAdded()"
            >
              <div
                class="v-list-item__content list"
                v-for="empresa in naoVinculados"
                :key="empresa.id_empresa"
              >
                <div class="list-equipe-items">
                  <div class="d-flex align-center">
                    <BaseSegmento :segmento="empresa.id_band" /> -

                    {{ empresa.apelido }}
                  </div>
                  <div class="mr-4">
                    <v-btn icon @click="add(empresa)">
                      <v-icon color="green">mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>Empresas
              Vinculadas
            </h4>
            <v-btn
              class="mb-3"
              color="error"
              @click="removerTodos()"
              :disabled="vinculados.length == 0"
            >
              Remover todas
            </v-btn>
            <draggable
              handle=".handle"
              class="draggable-area include-companies"
              :list="vinculados"
              group="equipes"
              @change="moveRemove()"
            >
              <div
                class="v-list-item__content"
                v-for="empresa in vinculados"
                :key="empresa.id_colab_mult_empresa_indicador"
              >
                <div class="list-equipe-participantes-items">
                  <div>
                    <v-btn class="mr-4" icon @click="remover(empresa)">
                      <v-icon color="red">mdi-arrow-left-bold</v-icon>
                    </v-btn>
                  </div>
                  <div class="list-equipe mr-4">
                    <div class="d-flex align-center">
                      <BaseSegmento :segmento="empresa.id_band" /> -

                      {{ empresa.apelido }}
                    </div>
                    <div class="d-flex align-center">
                      <strong class="mr-1">{{ empresa.id_indicador }}</strong>

                      - {{ empresa.indicador }}
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import draggable from "vuedraggable";
import BaseSelect from "@/components/shared/BaseSelectService";
import comissao from "@/services/http/comissaoService";
import sistema from "@/services/http/sistemaService";

export default {
  name: "DraggableColabMultEmp",

  components: {
    draggable,
    BaseSelect,
    BaseSegmento: () => import("@/components/shared/BaseSegmento.vue")
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    dadosColab: {
      type: Object
    }
  },

  data() {
    return {
      form: {
        id_indicador: ""
      },
      naoVinculados: [],
      vinculados: []
    };
  },

  watch: {
    dialog(value) {
      this.default();
      return value;
    }
  },

  methods: {
    async vinculo() {
      let empresas = await this.fetchEmpresa();

      this.vinculados = await this.fetchColabMultEmpIndic();

      let idVinculados = this.vinculados.map(item => {
        return parseInt(item.id_empresa);
      });

      this.naoVinculados = empresas.filter(
        item => !idVinculados.includes(item.id_empresa)
      );
    },

    async fetchColabMultEmpIndic() {
      if (!this.form.id_indicador) return [];
      let { data } = await comissao()
        .colaboradorEmpresaIndicador()
        .show({
          per_page: -1,
          id_colaborador: this.dadosColab.id_colaborador,
          id_indicador: this.form.id_indicador
        });
      return data.data;
    },

    async fetchEmpresa() {
      if (!this.form.id_indicador) return [];
      let { data } = await sistema()
        .empresa()
        .show({ per_page: -1, id_band: "2,4" });
      return data.data;
    },

    async addTodos() {
      try {
        let allPost = this.naoVinculados.map(item => {
          const form = {
            id_colaborador: this.dadosColab.id_colaborador,
            id_empresa: item.id_empresa,
            id_indicador: this.form.id_indicador,
            ativo: "S"
          };

          return comissao()
            .colaboradorEmpresaIndicador()
            .store(form, {
              notification: true,
              message: "Empresas vinculada com sucesso!"
            });
        });

        await Promise.all(allPost);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "error",
          text: `Erro ao adicionar empresa`
        });
      } finally {
        this.vinculo();
      }
    },
    async removerTodos() {
      try {
        let allDelete = this.vinculados.map(item => {
          return comissao()
            .colaboradorEmpresaIndicador(item.id_colab_mult_empresa_indicador)
            .delete(
              {},
              { notification: true, message: "Empresa removida com sucesso!" }
            );
        });
        await Promise.all(allDelete);
      } catch (error) {
        this.$notify({
          type: "error",
          text: `${error.response.data.message}` || `Erro ao remover empresa`
        });
      } finally {
        this.vinculo();
      }
    },

    async add(item) {
      try {
        const form = {
          id_colaborador: this.dadosColab.id_colaborador,
          id_empresa: item.id_empresa,
          id_indicador: this.form.id_indicador,
          ativo: "S"
        };
        await comissao()
          .colaboradorEmpresaIndicador()
          .store(form, {
            notification: true,
            message: "Empresa vinculada com sucesso!"
          });
      } catch (error) {
        this.$notify({
          type: "error",
          text: ` Erro ao adicionar  ${this.dadosColab.apelido}`
        });
      } finally {
        this.vinculo();
      }
    },

    async remover(item) {
      try {
        await comissao()
          .colaboradorEmpresaIndicador(item.id_colab_mult_empresa_indicador)
          .delete(
            {},
            { notification: true, message: "Empresa removida com sucesso!" }
          );
      } catch (error) {
        this.$notify({
          type: "error",
          text: `${error.response.data.message}` || `Erro ao remover Empresa`
        });
      } finally {
        this.vinculo();
      }
    },

    moveAdded(evt) {
      let empresa = {};
      const { removed } = evt;

      if (removed) {
        empresa = removed.element;
        this.add(empresa);
      }
    },

    moveRemove(evt) {
      let empresa = {};
      const { removed } = evt;

      if (removed) {
        empresa = removed.element;
        this.remover(empresa);
      }
    },

    close() {
      this.$emit("close");
    },

    default() {
      this.form.id_indicador = "";
      this.naoVinculados = [];
      this.vinculados = [];
    }
  }
};
</script>

<style scoped>
.v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.199);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  min-height: 300px;
  padding: 8px;
}

.draggable-area.include-companies {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
}

.draggable-area.exclude-companies {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
}
.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-items {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-participantes-items {
  display: flex !important;
}

.list-equipe {
  display: flex !important;
  flex-direction: column;
}
</style>
